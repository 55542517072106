import { useNavigation } from '@react-navigation/native';
import { isDateAfterLimit } from '_/helpers/isDateAfterLimit';
import { isAfter } from 'date-fns';

import { AppRoute, CheckinProps } from './../navigation/types';
import { useVisit } from './VisitContext';
import { useCentralizedValuesFallback } from './useCentralizedValuesFallback';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { colors } from '_/config/theme';
import { useIsDesktopConnected } from './useIsDesktopConnected';

const CHECKIN_FLOW = [
  AppRoute.CHECKIN,
  AppRoute.CHECKIN_INFORMATION,
  AppRoute.CHECKIN_HOST,
  AppRoute.CHECKIN_EXTRA_HOST_FORM,
  AppRoute.CHECKIN_PICTURE,
  AppRoute.CHECKIN_NDA,
  AppRoute.CHECKIN_QUESTIONNARIE,
  AppRoute.CHECKIN_MESSAGE,
];

export function useNextCheckinScreen(currentScreen: AppRoute) {
  const { visit } = useVisit();
  const { navigate } = useNavigation<CheckinProps>();
  const { t } = useTranslation();

  const desktopConnected = useIsDesktopConnected();

  function isScreenAfterCurrent(screen: AppRoute) {
    return (
      CHECKIN_FLOW.findIndex((route) => route === screen) >
      CHECKIN_FLOW.findIndex((route) => route === currentScreen)
    );
  }

  const { selectedOrFallbackCustomization } = useCentralizedValuesFallback();

  function goToNextScreen() {
    if (!desktopConnected && Platform.OS === 'web') {
      showMessage({
        message: t('error'),
        description: t('homeScreen.Connection'),
        backgroundColor: colors.danger,
      });
      return;
    }

    if (visit.isAnonymous) {
      if (
        isScreenAfterCurrent(AppRoute.CHECKIN_INFORMATION) &&
        selectedOrFallbackCustomization?.hostEnabled
      ) {
        if (!visit.hostId) {
          return navigate(AppRoute.CHECKIN_HOST);
        }
      }

      return navigate(AppRoute.CHECKIN_MESSAGE);
    }

    if (
      isScreenAfterCurrent(AppRoute.CHECKIN_HOST) &&
      selectedOrFallbackCustomization?.hostEnabled
    ) {
      if (!visit.hostId && !selectedOrFallbackCustomization?.metadata?.isHostQueueEnabled) {
        return navigate(AppRoute.CHECKIN_HOST);
      }
    }

    if (
      isScreenAfterCurrent(AppRoute.CHECKIN_EXTRA_HOST_FORM) &&
      selectedOrFallbackCustomization?.displayHostExtraInfoForm &&
      selectedOrFallbackCustomization?.extraHostFormFields &&
      selectedOrFallbackCustomization.extraHostFormFields?.length > 0 &&
      (!visit?.extraHostFormFields || !visit.extraHostFormFields?.length)
    ) {
      return navigate(AppRoute.CHECKIN_EXTRA_HOST_FORM);
    }

    if (
      isScreenAfterCurrent(AppRoute.CHECKIN_PICTURE) &&
      selectedOrFallbackCustomization?.pictureEnabled
    ) {
      const preRegistrationWithoutPicture = visit.expectedAt && !visit.pictureUrl;
      const visitWithoutPictureOrUniquePicture =
        !visit.expectedAt && (!visit.pictureUrl || !selectedOrFallbackCustomization?.pictureUnique);

      if (preRegistrationWithoutPicture || visitWithoutPictureOrUniquePicture) {
        return navigate(AppRoute.CHECKIN_PICTURE);
      }
    }

    if (isScreenAfterCurrent(AppRoute.CHECKIN_NDA) && selectedOrFallbackCustomization?.ndaEnabled) {
      const isNDAAfterLimit = isDateAfterLimit(
        selectedOrFallbackCustomization?.ndaValidForDays,
        visit?.ndaSignedAt as unknown as string
      );

      const ndaLastEdited = new Date(selectedOrFallbackCustomization?.ndaLastEdited ?? 0);
      const ndaSignedAt = new Date(visit?.ndaSignedAt ?? 0);

      const isAfterNdaLastEdited = isAfter(ndaLastEdited, ndaSignedAt);

      const preRegistrationWithoutNDA = visit.expectedAt && !visit.ndaUrl;
      const visitWithoutNDAorAfterLimit = !visit.expectedAt && (!visit.ndaUrl || isNDAAfterLimit);

      if (preRegistrationWithoutNDA || visitWithoutNDAorAfterLimit || isAfterNdaLastEdited) {
        return navigate(AppRoute.CHECKIN_NDA);
      }
    }

    if (
      isScreenAfterCurrent(AppRoute.CHECKIN_QUESTIONNARIE) &&
      selectedOrFallbackCustomization?.questionnaireEnabled &&
      selectedOrFallbackCustomization?.questionnaireFields &&
      selectedOrFallbackCustomization?.questionnaireFields?.length > 0
    ) {
      const isQuestionnaireAfterLimit = isDateAfterLimit(
        selectedOrFallbackCustomization?.questionnaireValidForDays,
        visit?.questionnaireAnsweredAt as unknown as string
      );

      const isQuestionnaireLastEditedAfterVisitAnswer = isAfter(
        new Date(selectedOrFallbackCustomization?.questionnaireLastEdited as unknown as string),
        new Date(visit?.questionnaireAnsweredAt as unknown as string)
      );

      const visitWithoutQuestionnaireOrAfterLimitOrEdited =
        !visit.expectedAt &&
        (!visit?.questionnaireAnsweredAt ||
          isQuestionnaireAfterLimit ||
          isQuestionnaireLastEditedAfterVisitAnswer);
      const preRegistrationQuestionnaireEditedAfterAnswered =
        visit.expectedAt &&
        (visit.questionnaireAnsweredAt || '') <
          (selectedOrFallbackCustomization.questionnaireLastEdited || '');

      if (
        visitWithoutQuestionnaireOrAfterLimitOrEdited ||
        preRegistrationQuestionnaireEditedAfterAnswered
      ) {
        return navigate(AppRoute.CHECKIN_QUESTIONNARIE);
      }
    }

    navigate(AppRoute.CHECKIN_MESSAGE);
  }

  return { goToNextScreen };
}
