import { BadgeConfig } from '@w3lcome/types';
import { badgeScale } from '_/constants/badgeScale';
import { BadgeInit } from '_/interfaces/BadgeInit';

export const paper62mmx100mm: BadgeInit = ({ visit, field1, field2, field3, image = '' }) => {
  const paper62mmx100mm: BadgeConfig = {
    width: 1000 * badgeScale,
    height: 620 * badgeScale,
    fields: [
      {
        type: 'text',
        subType: 'text',
        value: visit.name ?? '',
        styles: {
          top: 50 * badgeScale,
          left: 0 * badgeScale,
          width: 1000 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 82 * badgeScale,
          fontWeight: 'bold',
          textAlign: 'center',
        },
      },
      {
        type: 'image',
        subType: 'image',
        value: image,
        styles: {
          top: 238 * badgeScale,
          left: 56 * badgeScale,
          width: 237 * badgeScale,
          height: 237 * badgeScale,
          resizeMode: 'contain',
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field1,
        styles: {
          top: 240 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 52 * badgeScale,
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field2,
        styles: {
          top: 380 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 52 * badgeScale,
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field3,
        styles: {
          top: 510 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 52 * badgeScale,
        },
      },
    ],
  };

  return paper62mmx100mm;
};
