import { LoadingView } from '_/components';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomizationLgpd } from '../LgpdCheckinFlow/hooks/CustomizationLgpdContext';

const LoadingScreen: React.FC = () => {
  const { loadData } = useCustomization();
  const { endpointLgpd } = useAuth();

  const { loadDataLgpd } = useCustomizationLgpd();
  const { t } = useTranslation();

  useEffect(() => {
    if (endpointLgpd) {
      loadDataLgpd();
      return;
    }

    loadData();
  }, []);

  return (
    <LoadingView
      title={t('LoadingScreen.Downloading')}
      subtitle={t('LoadingScreen.DataFromServer')}
    />
  );
};

export default LoadingScreen;
