import { colors } from '_/config/theme';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { BarCodeScanningResult, Camera, CameraType } from 'expo-camera/build/legacy';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Easing, StyleSheet, Platform } from 'react-native';
import { View } from 'react-native-animatable';
import BarcodeMask from 'react-native-barcode-mask';
import QRCodeReaderWeb from 'react-qr-reader';

import Button from '../Button';
import ModalBackground from '../ModalBackground';
import Text from '../Text';

interface ModalQRCodeCameraProps {
  isScannerEnabled: boolean;
  isVisible: boolean;
  cameraType?: CameraType;
  onClose: () => void;
  onScan: (data: string) => void;
}

const ModalQRCodeCamera: React.FC<ModalQRCodeCameraProps> = ({
  isScannerEnabled,
  isVisible,
  cameraType = CameraType.front,
  onClose,
  onScan,
}) => {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState<boolean>(null as any);
  const animation = useRef(new Animated.Value(0));

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  async function handleBarCodeScanned({ data }: BarCodeScanningResult) {
    if (!isScannerEnabled) {
      return;
    }

    onScan(data);
  }

  async function handleScan(data?: string) {
    if (!isScannerEnabled) {
      return;
    }

    if (data) {
      onScan(data);
    }
  }

  useEffect(() => {
    Animated.timing(animation.current, {
      duration: 400,
      toValue: isVisible ? 1 : 0,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [isVisible]);

  return (
    <ModalBackground isVisible={isVisible} onClose={onClose}>
      <Text
        style={{
          opacity: animation.current.interpolate({
            inputRange: [0, 0.4, 1],
            outputRange: [0, 0, 1],
            extrapolate: 'clamp',
          }),
        }}
        center
        size={40}
        fontType="bold"
      >
        {t('qrCodeModal.pointToCamera')}
      </Text>
      <Animated.View
        style={[
          styles.cameraContainer,
          {
            opacity: animation.current.interpolate({
              inputRange: [0, 0.4, 1],
              outputRange: [0, 0, 1],
              extrapolate: 'clamp',
            }),
          },
        ]}
      >
        {hasPermission ? (
          <>
            {Platform.OS === 'web' ? (
              <QRCodeReaderWeb
                onError={() => null}
                showViewFinder={false}
                delay={300}
                onScan={handleScan}
                style={{ width: 400, height: 400 }}
              />
            ) : (
              <Camera
                onBarCodeScanned={handleBarCodeScanned}
                type={cameraType}
                style={styles.camera}
                barCodeScannerSettings={{
                  barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                }}
              >
                <BarcodeMask edgeRadius={4} edgeColor={colors.white} showAnimatedLine={false} />
              </Camera>
            )}
          </>
        ) : (
          <View style={styles.cameraDenied}>
            <Text center size={24} color={colors.white}>
              {t('qrCodeModal.permissionDenied')}
            </Text>
          </View>
        )}
      </Animated.View>
      <Animated.View
        style={{
          opacity: animation.current.interpolate({
            inputRange: [0, 0.8, 1],
            outputRange: [0, 0, 1],
            extrapolate: 'clamp',
          }),
        }}
      >
        <Button leftIcon="x" large onPress={onClose} outline>
          {t('qrCodeModal.closeCamera')}
        </Button>
      </Animated.View>
    </ModalBackground>
  );
};

export default ModalQRCodeCamera;

const styles = StyleSheet.create({
  camera: {
    width: 400,
    height: 400,
  },
  cameraContainer: {
    marginVertical: 40,
    width: 400,
    height: 400,
    borderRadius: 8,
    overflow: 'hidden',
  },
  cameraDenied: {
    width: 400,
    height: 400,
    backgroundColor: colors.dark2,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
});
