import { useNavigation } from '@react-navigation/native';
import { IpadLang } from '@w3lcome/types';
import { HTMLRender, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { fontSizes } from '_/config/theme';
import { textReplace } from '_/helpers/textReplace';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useDelivery } from '_/hooks/DeliveryContext';
import { AppRoute, DeliveryMessageProps } from '_/navigation/types';
import i18n from '_/services/locale';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';

const DeliveryMessageScreen: React.FC = () => {
  const { customization } = useCustomization();
  const { t } = useTranslation();
  const { delivery, insertDelivery, insertDeliveryResponsibleHost } = useDelivery();

  const navigation = useNavigation<DeliveryMessageProps>();

  useEffect(() => {
    if (customization?.deliveriesResponsibleHosts) {
      insertDeliveryResponsibleHost();
    } else {
      insertDelivery(delivery);
    }
    const timeoutId = setTimeout(() => {
      navigation.navigate(AppRoute.HOME);
    }, (customization?.ipadDeliveriesDuration || 6) * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [customization?.ipadDeliveriesDuration]);

  const ipadDeliveriesMessage = useMemo(() => {
    return textReplace(
      customization?.ipadDeliveriesMessage || '',
      i18n.language as IpadLang,
      customization,
      undefined,
      delivery.hosts?.[0]
    );
  }, [customization]);

  return (
    <View style={styles.container}>
      <ContainerWidthLimit>
        <View style={styles.content}>
          <View style={{ alignItems: 'center' }}>
            <Text size={fontSizes.xxl}>{t('deliveryScreen.message')}</Text>
          </View>
          <View pointerEvents="none">
            {!!customization?.ipadDeliveriesMessage && <HTMLRender html={ipadDeliveriesMessage} />}
          </View>
        </View>
      </ContainerWidthLimit>
    </View>
  );
};

export default DeliveryMessageScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingHorizontal: 64,
    textAlign: 'center',
  },
});
