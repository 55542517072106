import { useIsFocused } from '@react-navigation/core';
import { images } from '_/assets';
import { Header, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { colors, defaultShadow, fontSizes } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { AppRoute } from '_/navigation/types';
import logger from '_/services/logger';
import { Camera, CameraCapturedPicture, CameraType } from 'expo-camera/build/legacy';
import Constants from 'expo-constants';
import * as ImageManipulator from 'expo-image-manipulator';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleSheet,
  View,
  ImageBackground,
  Animated,
  TouchableOpacity,
  Image,
  Platform,
  useWindowDimensions,
  Easing,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import BottomModalContainer from './BottomModalContainer';

const CheckinPictureScreen: React.FC = () => {
  const { t } = useTranslation();
  const [startCameraState, setStartCameraState] = useState(false);
  const [webTimerLabel, setWebTimerLabel] = useState('');
  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN_PICTURE);
  const isFocused = useIsFocused();
  const { width, height } = useWindowDimensions();

  const animatedLabel = useRef(new Animated.Value(0)).current;
  const webTimeoutRef = useRef<number>();
  const camera = useRef<Camera>(null);

  const { visit, setVisit } = useVisit();
  const { ipad } = useAuth();

  useEffect(() => {
    if (isFocused) {
      startCamera();
    }
  }, [isFocused]);

  const startCamera = async () => {
    if (visit.picture) {
      return;
    }

    const { status } = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      setStartCameraState(true);
      toggleLabel(true);
    }
  };

  function startTimer() {
    setWebTimerLabel('5');
    webTimeoutRef.current = window.setTimeout(() => {
      setWebTimerLabel('4');
      webTimeoutRef.current = window.setTimeout(() => {
        setWebTimerLabel('3');
        webTimeoutRef.current = window.setTimeout(() => {
          setWebTimerLabel('2');
          webTimeoutRef.current = window.setTimeout(() => {
            setWebTimerLabel('1');
            webTimeoutRef.current = window.setTimeout(() => {
              takePicture();
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    }, 2000);
  }

  const toggleLabel = (isVisible: boolean) => {
    Animated.timing(animatedLabel, {
      toValue: isVisible ? 1 : 0,
      duration: 600,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.inOut(Easing.cubic),
    }).start();
  };

  const takePicture = async () => {
    if (!camera.current || visit.picture) {
      return;
    }

    try {
      toggleLabel(false);
      setWebTimerLabel('');
      clearTimeout(webTimeoutRef.current);
      const picture = await camera.current?.takePictureAsync();
      if (picture?.uri) {
        const flippedPicture = await ImageManipulator.manipulateAsync(
          picture.uri,
          [{ flip: ImageManipulator.FlipType.Horizontal }],
          { compress: 1, base64: true }
        );
        setVisit({ picture: flippedPicture });
      } else {
        setVisit({ picture });
      }
    } catch (error) {
      logger(error);
      showMessage({
        message: t('error'),
        description: t('AccessDenied'),
        backgroundColor: colors.danger,
      });
    }
  };

  const retakePicture = () => {
    setVisit({ picture: null });
    startCamera();
  };

  const handleSkipPress = () => {
    setVisit({ picture: null });
    setTimeout(goToNextScreen, 400);
  };

  const handleNextPress = () => {
    goToNextScreen();
  };

  const handlePressScreen = () => {
    takePicture();
    clearTimeout(webTimeoutRef.current);
  };

  // const handleFacesDetected = (faces: FaceDetectionResult) => {
  //   const isSmiling = faces.faces.some((face) => {
  //     return face.smilingProbability > 0.6;
  //   });
  //   if (isSmiling) {
  //     takePicture();
  //   }
  // };

  const PicturePreview = ({ photo }: { photo: CameraCapturedPicture }) => {
    return (
      <View style={styles.preview}>
        <ImageBackground source={{ uri: photo.uri }} style={{ flex: 1 }} />
      </View>
    );
  };

  const handleGoBack = () => {
    setVisit({ picture: null });
  };

  return (
    <View style={styles.container}>
      <ContainerWidthLimit>
        <Header onGoBack={handleGoBack} />
        <View style={styles.content}>
          {startCameraState ? (
            <View style={styles.innerContentShadow}>
              <View style={styles.innerContent}>
                {visit.picture ? (
                  <PicturePreview photo={visit.picture} />
                ) : (
                  <Camera
                    onCameraReady={startTimer}
                    // onFacesDetected={handleFacesDetected}
                    // faceDetectorSettings={
                    //   Platform.OS !== 'web' && Constants.expoConfig?.extra?.isSmilePictureEnable
                    //     ? {
                    //         mode: FaceDetector.FaceDetectorMode.fast,
                    //         detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
                    //         runClassifications: FaceDetector.FaceDetectorClassifications.all,
                    //         minDetectionInterval: 200,
                    //         tracking: true,
                    //       }
                    //     : undefined
                    // }
                    type={
                      (ipad?.settingsPictureCamera.toLowerCase() as CameraType) || CameraType.front
                    }
                    style={{ flex: 1, width: '100%', height: '100%' }}
                    ref={camera}
                  >
                    <TouchableOpacity style={styles.touch} onPress={handlePressScreen}>
                      {height >= width && (
                        <>
                          <View style={styles.picturePlaceholderBorder} />
                          <Image
                            source={images.picturePlaceholder}
                            style={styles.picturePlaceholder}
                          />
                          <View style={styles.picturePlaceholderBorder} />
                        </>
                      )}
                    </TouchableOpacity>
                  </Camera>
                )}
                <View pointerEvents="none" style={styles.labelContainer}>
                  <Text center size={80} style={[styles.label, { marginTop: -12 }]}>
                    {webTimerLabel}
                  </Text>

                  <Animated.View
                    style={{
                      opacity: animatedLabel.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 0.9],
                        extrapolate: 'clamp',
                      }),
                      transform: [
                        {
                          scale: animatedLabel.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0.5, 1],
                          }),
                        },
                        {
                          translateY: animatedLabel.interpolate({
                            inputRange: [0, 1],
                            outputRange: [500, 0],
                          }),
                        },
                      ],
                    }}
                  >
                    <Text center size={Platform.OS === 'web' ? 80 : 120} style={styles.label}>
                      {t(`pictureScreen.${Constants.expoConfig?.extra?.labelPicture}`)}
                    </Text>
                    <Text center size={30} style={[styles.label, styles.bottomLabel]}>
                      {t(`pictureScreen.${Constants.expoConfig?.extra?.labelBottomPicture}`)}
                    </Text>
                  </Animated.View>
                </View>
              </View>
            </View>
          ) : (
            <View style={styles.innerContentShadow}>
              <View style={styles.innerContent}>
                <View style={{ width: '100%', height: '100%', flex: 1 }}>
                  <View style={styles.center}>
                    <Text size={fontSizes.xl} color={colors.dark2} fontType="semibold">
                      {t('pictureScreen.CameraPermissionDenied')}
                    </Text>
                    <Text size={fontSizes.xl} color={colors.dark2} fontType="semibold">
                      {t('pictureScreen.ContinueButton')}
                    </Text>
                  </View>
                  <View style={styles.touch}>
                    <View style={styles.picturePlaceholderBorder} />
                    <Image
                      source={
                        height >= width
                          ? images.picturePlaceholder
                          : images.picturePlaceholderHorizontal
                      }
                      style={styles.picturePlaceholder}
                    />
                    <View style={styles.picturePlaceholderBorder} />
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
        <BottomModalContainer
          onNextPress={handleNextPress}
          onRetakePress={retakePicture}
          onSkipPress={handleSkipPress}
          cameraAllowed={startCameraState}
          isVisible={!!visit.picture || !startCameraState}
        />
      </ContainerWidthLimit>
    </View>
  );
};

export default CheckinPictureScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    paddingHorizontal: 32,
    paddingBottom: 100,
  },
  innerContentShadow: {
    flex: 1,
    borderRadius: 8,
    ...defaultShadow,
  },
  innerContent: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden',
  },
  touch: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    flexDirection: 'row',
  },
  labelContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  preview: {
    backgroundColor: 'transparent',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  label: {
    color: colors.white,
    textTransform: 'uppercase',
    textShadowColor: '#222',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 30,
    paddingHorizontal: 20,
  },
  bottomLabel: {
    marginTop: -20,
    marginBottom: 20,
  },
  picturePlaceholderBorder: {
    flex: Platform.OS === 'web' ? 1 : 0,
    backgroundColor: '#323438',
    opacity: 0.3,
  },
  picturePlaceholder: {
    ...Platform.select({
      web: {
        aspectRatio: 1,
      },
      ios: {
        flex: 1,
      },
      android: {
        flex: 1,
      },
    }),
    height: '100%',
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
