import { Feather } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { colors, fontFamily, fontSizes } from '_/config/theme';
import { ExtendedCountryCode, countries } from '_/helpers/countryDials';
import { useVisit } from '_/hooks/VisitContext';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Platform,
  TextInput,
  View,
  useWindowDimensions,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';

import Modal from '../Modal';
import { CountryItem } from './CountryItem';
import KeyboardContainer from '_/components/KeyboardContainer';


const ModalCountryPicker: React.FC = () => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { setVisit, setCountryPickerModalActive, countryPickerModalActive } = useVisit();
  const [dataCountries, setDataCountries] = useState(countries);
  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const [searchText, setSearchText] = useState('');
  const inputSearchRef = useRef<TextInput>(null);

  function handleChangeCountryCode(code: ExtendedCountryCode) {
    setVisit({ countryCode: code });
    resetData();
  }

  function resetData() {
    setCountryPickerModalActive(false);
    setSearchText('');
    setDataCountries(countries);
  }

  function searchCountry(text: string) {
    if (text.length > 0) {
      const filtered = countries.filter(({ name, countryCode }) =>
        name?.toLowerCase().includes(text.toLowerCase()) ||
        countryCode?.toLowerCase().includes(text.toLowerCase())
      );
      setDataCountries(filtered);
    } else {
      setDataCountries(countries);
    }

    setSearchText(text);
  }

  useFocusEffect(() => {
    setTimeout(() => {
      if (inputSearchRef && inputSearchRef.current && countryPickerModalActive) {
        return inputSearchRef.current?.focus();
      }
    }, 1000);
  });

  if (!countryPickerModalActive) {
    return null;
  }

  return (
    <KeyboardContainer
    keyboardOpen={isFocusedInput}
    onSubmit={resetData}
    inputName="search"
    onChange={searchCountry}
    keyboardType='default'
  >
    <KeyboardAvoidingView
      enabled
      style={{width: '100%'}}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <Modal isVisible={countryPickerModalActive} >
        <View style={{ width: '100%' }}>
              <View style={styles.header}>
                <TextInput
                  autoCorrect={false}
                  ref={inputSearchRef}
                  style={styles.input}
                  placeholder={t('searchCountryName')}
                  value={searchText}
                  onFocus={()=> setIsFocusedInput(true)}
                  onBlur={()=> setIsFocusedInput(false)}
                  onChangeText={searchCountry}
                />
                <TouchableOpacity style={styles.closeButton} activeOpacity={1} onPress={resetData}>
                  <Feather name="x" size={24} color={colors.dark3} />
                </TouchableOpacity>
              </View>

              <FlatList
                style={{ height: height / 2 }}
                contentContainerStyle={{ paddingHorizontal: 24 }}
                data={dataCountries}
                keyboardShouldPersistTaps="always"
                showsVerticalScrollIndicator={Platform.OS === 'web'}
                keyExtractor={(item) => item.code}
                initialNumToRender={20}
                maxToRenderPerBatch={20}
                removeClippedSubviews
                renderItem={({ item }) => (
                  <CountryItem country={item} handleChangeCountryCode={handleChangeCountryCode} />
                )}
              />
              </View>
            </Modal>
        </KeyboardAvoidingView>
      </KeyboardContainer>
  );
};

export default ModalCountryPicker;

const styles = StyleSheet.create({
  input: {
    color: colors.dark3,
    marginRight: 12,
    flex: 1,
    height: 40,
    alignSelf: 'center',
    fontSize: fontSizes.md2,
    fontFamily: fontFamily.regular,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
    borderBottomColor: colors.light3,
    borderBottomWidth: 1,
    paddingBottom: 12,
  },
  closeButton: {
    padding: 12,
  },
});
