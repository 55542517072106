import { HostsModel } from '@w3lcome/types';
import { hostApi } from '_/services/api';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from './AuthContext';
import { useNetInfo } from '@react-native-community/netinfo';

interface HostContextData {
  hostsList: HostsModel[];
  searchHost(text?: string): void;
}

const HostsContext = createContext<HostContextData>({} as HostContextData);

type HostsType = {
  children: React.ReactNode;
};

type HostSocketResponse = {
  data: HostsModel;
  hasMultipleHosts: boolean;
};

export const HostsProvider: React.FC<HostsType> = ({ children }) => {
  const { ipad, feathersApp } = useAuth();
  const { isConnected } = useNetInfo();

  const [hostsList, setHostsList] = useState<HostsModel[]>([]);
  const [hostsListAll, setHostsListAll] = useState<HostsModel[]>([]);

  const setHostStates = (data: HostsModel[]) => {
    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
    setHostsList(sortedData);
    setHostsListAll(sortedData);
  };

  const getAllHosts = async () => {
    const { data, limit, total } = await hostApi.getListHost();

    let allHosts: HostsModel[] = data;
    let loopCount = 0;
    let skip = limit;
    const maxLoops = 50;

    while (skip < total && loopCount < maxLoops) {
      const newHosts = await hostApi.getListHost({ $skip: skip });
      allHosts = allHosts.concat(newHosts.data);
      skip += newHosts.limit;
      loopCount++;
    }

    if (loopCount >= maxLoops) {
      console.warn(
        'Max number of loops reached when trying to get all hosts, breaking to prevent potential infinite loop.'
      );
    }

    setHostStates(allHosts);
  };

  const upsertOrDeleteHosts = (
    { data, hasMultipleHosts }: HostSocketResponse,
    isDeleted: boolean
  ) => {
    if (hasMultipleHosts) {
      return getAllHosts();
    }

    const hostIndex = hostsListAll.findIndex(
      (host) => host.id === data.id || host.email === data.email
    );
    let newHostList: HostsModel[];
    if (hostIndex < 0) {
      newHostList = [...hostsListAll, data];
    } else if (isDeleted) {
      newHostList = [...hostsListAll];
      newHostList.splice(hostIndex, 1);
    } else {
      newHostList = [...hostsListAll];
      newHostList[hostIndex] = data;
    }

    setHostStates(newHostList);
  };

  const deleteHost = (data: HostSocketResponse) => upsertOrDeleteHosts(data, true);

  useEffect(() => {
    if (!ipad || !isConnected) {
      return;
    }

    getAllHosts();
  }, [ipad, isConnected]);

  useEffect(() => {
    feathersApp?.service('hosts').on('created', upsertOrDeleteHosts);
    feathersApp?.service('hosts').on('patched', upsertOrDeleteHosts);
    feathersApp?.service('hosts').on('removed', deleteHost);

    return () => {
      feathersApp?.service('hosts').off('created', upsertOrDeleteHosts);
      feathersApp?.service('hosts').off('patched', upsertOrDeleteHosts);
      feathersApp?.service('hosts').off('removed', deleteHost);
    };
  }, [feathersApp, hostsListAll]);

  // function removeAccent(str: string) {
  //   return str.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
  // }

  const searchHost = async (text?: string) => {
    if (text) {
      const filtered = hostsListAll.filter(
        ({ name, email }) =>
          name.toLowerCase().includes(text.toLowerCase()) ||
          email?.toLowerCase().includes(text.toLowerCase())
      );

      return setHostsList(filtered);
    }

    return setHostsList(hostsListAll);
  };

  return (
    <HostsContext.Provider value={{ hostsList, searchHost }}>{children}</HostsContext.Provider>
  );
};

export function useHost(): HostContextData {
  const context = useContext(HostsContext);

  if (!context) {
    throw new Error('useHost must be used within an HostProvider');
  }

  return context;
}
