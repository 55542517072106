export enum BadgeFieldType {
  Host = 'Host',
  MeetingTitle = 'Meeting.Title',
  MeetingTitleDate = 'Meeting.Title.Date',
  MeetingLocation = 'Meeting.Location',
  MeetingLocationDate = 'Meeting.Location.Date',
  ArrivedDate = 'ArrivedDate',
  CheckinAt = 'CheckinAt',
  Message = 'Message',
  cardNumber = 'cardNumber',
  name = 'name',
  email = 'email',
  phone = 'phone',
  cpf = 'cpf',
  rg = 'rg',
  document = 'document',
  companyname = 'companyname',
  address = 'address',
  position = 'position',
  plate = 'plate',
  sector = 'sector',
  chosen_name = 'chosen_name',
}
