import { useNavigation } from '@react-navigation/core';
import { Feather } from '@expo/vector-icons';
import { CompaniesModel } from '@w3lcome/types';
import { Text, Header, Button } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors } from '_/config/theme';
import { useChildrenCompanies } from '_/hooks/ChildrenCompaniesContex';
import { AppRoute, CompanySelectProps } from '_/navigation/types';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  StyleSheet,
  TextInput,
  Image,
  TouchableOpacity,
  FlatList,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { useCustomization } from '_/hooks/CustomizationContext';

const CheckinCompanySelectScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CompanySelectProps>();
  const { setSelectedCompanyId } = useChildrenCompanies();
  const { companies } = useChildrenCompanies();
  const { customization } = useCustomization();

  const inputSearchRef = useRef<TextInput>(null);

  const [textSearch, setTextSearch] = useState('');
  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const [searchCompanies, setSearchCompanies] = useState<typeof companies>([]);

  const orderAlphabeticalCompaniesByName = (companies: CompaniesModel[]) => {
    return companies.slice().sort((companie1, companie2) => {
      return companie1.name.toLowerCase().localeCompare(companie2.name.toLowerCase());
    });
  };

  const orderAlphabeticalCompaniesList = useMemo(() => {
    return orderAlphabeticalCompaniesByName(searchCompanies as CompaniesModel[]);
  }, [searchCompanies]);

  useEffect(() => {
    const convertTextSearchforLowerCase = textSearch.toLowerCase();

    const filteredCompanies = companies?.filter((company) => {
      const convertCompanyNameForLowerCase = company.name.toLowerCase();
      return convertCompanyNameForLowerCase.includes(convertTextSearchforLowerCase);
    });

    setSearchCompanies(filteredCompanies);
  }, [textSearch]);

  function onSelectCompany(company: CompaniesModel) {
    setSelectedCompanyId(company.id);
    handleNextScreen();
  }

  function handleNextScreen() {
    navigation.navigate(AppRoute.CHECKIN);
  }

  function handleGoBack() {
    navigation.navigate(AppRoute.HOME);
  }

  function onChange(text: string) {
    setTextSearch(text);
  }

  return (
    <KeyboardContainer
      keyboardOpen={isFocusedInput}
      onChange={onChange}
      inputName="search"
      keyboardType="default"
    >
      <KeyboardAvoidingView
        enabled
        style={StyleSheet.absoluteFill}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      >
        <View style={styles.container}>
          <ContainerWidthLimit>
            <Header onGoBack={handleGoBack} />

            <View style={styles.content}>
              <Text style={styles.TextTitle}>{t('checkinScreen.companySelect.title')}</Text>
              <View style={styles.lineHorizontal} />

              <View style={styles.searchContent}>
                <Feather name="search" size={20} color={colors.dark3} />
                <TextInput
                  style={styles.textSearch}
                  onChangeText={onChange}
                  value={textSearch}
                  ref={inputSearchRef}
                  placeholder={t('checkinScreen.companySelect.placeholder')}
                  keyboardType="default"
                  autoCorrect={false}
                  placeholderTextColor={colors.black}
                  onFocus={() => setIsFocusedInput(true)}
                  onBlur={() => setIsFocusedInput(false)}
                />
              </View>

              <View style={styles.lineHorizontal} />

              <FlatList
                data={orderAlphabeticalCompaniesList}
                keyExtractor={(item) => item.id.toString()}
                scrollEventThrottle={16}
                numColumns={3}
                renderItem={({ item }) => {
                  return (
                    <View key={item.id} style={styles.companyContainer}>
                      <TouchableOpacity
                        onPress={() => onSelectCompany(item)}
                        style={styles.companyView}
                      >
                        <Image
                          style={styles.logo}
                          resizeMode="contain"
                          source={{
                            uri: `${item.pictureUrl}`,
                          }}
                        />
                        <Text style={styles.TextName}>{item.name}</Text>
                      </TouchableOpacity>
                    </View>
                  );
                }}
                ListFooterComponent={
                  <>
                    <Button
                      backgroundColor={customization?.mainColor || colors.primary}
                      labelColor={customization?.backgroundColor || colors.white}
                      buttonStyles={styles.button}
                      onPress={() => navigation.navigate(AppRoute.CHECKIN)}
                      children={t('checkinScreen.companySelect.button')}
                    />
                  </>
                }
              />
            </View>
          </ContainerWidthLimit>
        </View>
      </KeyboardAvoidingView>
    </KeyboardContainer>
  );
};

export default CheckinCompanySelectScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 0.9,
    marginHorizontal: 32,
  },
  TextTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  },
  searchContent: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  textSearch: {
    width: '100%',
    paddingLeft: 16,
    marginVertical: 16,
    fontSize: 20,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  companyContainer: {
    width: '33.33%',
  },
  companyView: {
    height: 160,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lineHorizontal: {
    backgroundColor: colors.light3,
    height: 1,
    width: '100%',
  },
  logo: {
    height: '60%',
    width: '60%',
  },
  TextName: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  button: {
    marginVertical: 32,
  },
  arrow: {
    textAlign: 'center',
  },
});
