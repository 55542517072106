import { HostsModel } from '@w3lcome/types';

import logger from '../logger';
import RequestCreator from './BaseRequest';

interface HostType {
  total: number;
  limit: number;
  skip: number;
  data: HostsModel[];
}

class Host extends RequestCreator<HostsModel> {
  private childrenHostsPath = '/ipad/children/hosts';

  getListHost = async (params = {}) => {
    try {
      const result = await this.api.get<HostType>(this.path, {
        params: { isActive: true, ...params },
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getListChildrenHosts = async (params = {}) => {
    try {
      const result = await this.api.get<HostType>(this.childrenHostsPath, {
        params: { isActive: true, ...params },
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  searchHost = async (value: string) => {
    try {
      const result = await this.api.get<HostType>(`${this.path}`, {
        params: {
          '$or[0][name][$iLike]': `%${value}%`,
          '$or[1][email][$iLike]': `%${value}%`,
          '$sort[name]': 1,
          isActive: true,
        },
      });

      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
}

const api = new Host('/ipad/hosts');
export default api;
