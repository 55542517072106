import { PrintersModel, Lang, BadgeConfig } from '@w3lcome/types';
import appConfig from '_/config/app';
import { getDateLocale } from '_/helpers/getDateLocale';
import { BadgeFieldType } from '_/interfaces/BadgeFieldType';
import { BadgeImageType } from '_/interfaces/BadgeImageType';
import { PrinterPaper } from '_/interfaces/PrinterPaper';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { format, parseISO } from 'date-fns';
import QRCode from 'qrcode-generator';

import { badges, testBadges } from './badges';
import { downloadAndConvertToBase64 } from './downloadAndConvertToBase64';

function generateQRCode(value: string) {
  const qrcode = QRCode(0, 'L');
  qrcode.addData(value);
  qrcode.make();
  return qrcode.createDataURL(6, 2);
}

async function getBadgeImage(
  visit: Partial<VisitsModelCheckin>,
  badgeImage: string | null,
  qrcodeURL: string | null,
  companyLogo?: string | null
): Promise<string> {
  if (visit.cardNumber) {
    return generateQRCode(visit.cardNumber);
  }

  if (badgeImage === BadgeImageType.Logo) {
    return downloadAndConvertToBase64(companyLogo);
  }

  if (badgeImage === BadgeImageType.QRCode) {
    const qrcode = qrcodeURL ?? `${appConfig.company}://visit/${visit.id}`;
    return generateQRCode(qrcode);
  }

  if (badgeImage === BadgeImageType.Picture) {
    if (visit.picture?.base64) {
      const pictureBase64 = visit.picture.base64;
      if (pictureBase64.includes(';base64,')) {
        return pictureBase64;
      } else {
        const prefix = 'data:image/jpeg;base64,';
        return prefix + pictureBase64;
      }
    } else if (visit.pictureMediumUrl) {
      return downloadAndConvertToBase64(visit.pictureMediumUrl);
    }
  }

  return '';
}

function getBadgeField(
  visit: Partial<VisitsModelCheckin>,
  field: string | null,
  printer: PrintersModel,
  lang?: Lang | null
): string {
  if (field === BadgeFieldType.Host) {
    return visit.host?.name ?? '';
  }

  if (visit.meeting && field === BadgeFieldType.MeetingTitleDate) {
    const locale = getDateLocale(lang);
    return `${visit.meeting.title} (${format(parseISO(visit.meeting.start as any), 'Pp', {
      locale,
    })})`;
  }

  if (visit.meeting && field === BadgeFieldType.MeetingTitle) {
    return visit.meeting.title ?? '';
  }

  if (visit.meeting && field === BadgeFieldType.MeetingLocation) {
    return visit.meeting.location ?? '';
  }

  if (visit.meeting && field === BadgeFieldType.MeetingLocationDate) {
    const locale = getDateLocale(lang);
    return `${visit.meeting.location} (${format(parseISO(visit.meeting.start as any), 'Pp', {
      locale,
    })})`;
  }

  if (field === BadgeFieldType.ArrivedDate || field === BadgeFieldType.CheckinAt) {
    const locale = getDateLocale(lang);
    if (typeof visit.checkinAt === 'string') {
      return format(parseISO(visit.checkinAt), 'Pp', { locale });
    }

    return format(visit.checkinAt as any, 'Pp', { locale });
  }

  if (field === BadgeFieldType.Message) {
    return printer.badgeMessage ?? '';
  }

  if (field && visit[field.toLowerCase() as keyof VisitsModelCheckin]) {
    return (visit[field.toLowerCase() as keyof VisitsModelCheckin] as string) ?? '';
  }

  return '';
}

export async function getBadge(
  visit: Partial<VisitsModelCheckin>,
  printer: PrintersModel,
  lang?: Lang | null,
  companyLogo?: string | null
): Promise<BadgeConfig | undefined> {
  const badgeConfig = printer.badgeConfig;

  if (!badgeConfig) {
    const image = await getBadgeImage(visit, printer.badgeImage, printer.qrcodeURL, companyLogo);
    const field1 = getBadgeField(visit, printer.badgeFirstField, printer, lang);
    const field2 = getBadgeField(visit, printer.badgeSecondField, printer, lang);
    const field3 = getBadgeField(visit, printer.badgeThirdField, printer, lang);

    const badgeInit = badges[printer.paper as PrinterPaper];

    return badgeInit?.({ visit, field1, field2, field3, image });
  }

  const fieldPromises = badgeConfig.fields.map(async (field) => {
    if (field.type === 'image') {
      const image = await getBadgeImage(visit, field.subType, printer.qrcodeURL, companyLogo);
      return { ...field, value: image };
    } else if (field.type === 'text') {
      const value = getBadgeField(visit, field.subType, printer, lang);
      return { ...field, value };
    }
    return field;
  });

  const updatedField = await Promise.all(fieldPromises);
  badgeConfig.fields = updatedField;

  return badgeConfig;
}

export function getTestBadge(printer: PrintersModel): BadgeConfig | undefined {
  return testBadges[printer.paper as PrinterPaper];
}
